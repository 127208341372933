import actions from '@/modules/common/store/entityActions'
import CustomerLog from '@/modules/customer/domain/customerLog'
import customerLogService from '@/modules/customer/services/customerLogService'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'

const customerLogModule: Module<EntityState<CustomerLog>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(customerLogService, 'customer-log')
}

export default customerLogModule
