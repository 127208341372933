import CompanyState from '@/modules/company/store/companyState'
import { createMutations } from '@/modules/common/store/entityMutations'
import { MutationTree } from 'vuex'
import { SELECT_MANAGED } from '@/modules/company/store/companyMutationTypes'

const mutations: MutationTree<CompanyState> = createMutations()

mutations[SELECT_MANAGED] = (state, action) => {
  state.managed = action.item
}

export default mutations
