import actions from './authActions'
import AuthState from '@/modules/auth/store/authState'
import mutations from './authMutations'
import getters from './authGetters'

const initialState = new AuthState()

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  mutations: mutations,
  actions: actions
}
