import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import mutations from '@/modules/common/store/entityMutations'
import ProgramApply from '@/modules/program/domain/programApply'
import programApplyService from '@/modules/program/services/programApplyService'
import RootState from '@/store/rootState'

const programApplyModule: Module<EntityState<ProgramApply>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(programApplyService, 'programApply')
}

export default programApplyModule
