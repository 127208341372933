import customerLogsPage from '../components/customerLogsPage.vue'
import customersPage from '../components/customersPage.vue'
import customerPage from '../components/customerPage.vue'

export default [
  {
    path: '/customers',
    name: 'customers',
    component: customersPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  },
  {
    path: '/customers/:customerId',
    name: 'customer',
    component: customerPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  },
  {
    path: '/customer-logs',
    name: 'customerLogs',
    component: customerLogsPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  }
]
