








import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import page from '../../common/components/page.vue'
import { State } from 'vuex-class'
import usersTable from '@/modules/user/components/usersTable.vue'
import Vue from 'vue'

@Component({
  components: { usersTable, page }
})
export default class UsersPage extends Vue {
  @State('managed', { namespace: 'company' }) company!: Company

  breadCrumbData = new BreadCrumbData([{ textKey: 'admin.label' }, { textKey: 'users.label' }])
  query: string | null = null
}
