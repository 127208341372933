import { MutationTree } from 'vuex'
import { CLEAR_ALL, CLEAR_LATEST, CLEAR_SELECTED, LIST, LIST_LATEST, SELECT } from './entityMutationTypes'
import EntityState from './entityState'
import { Entity } from '@/modules/common/domain/entity'

const mutations: MutationTree<EntityState<any>> = {
  [LIST] (state, action: any) {
    state.items = action.items
    state.parameters = action.parameters
  },
  [LIST_LATEST] (state, action) {
    state.latestItems = action.items
    state.parametersLatest = action.parameters
  },
  [SELECT] (state, action: any) {
    state.selected = action.item
  },
  [CLEAR_ALL] (state) {
    state.items = []
    state.parameters = null
  },
  [CLEAR_LATEST] (state) {
    state.latestItems = []
    state.parameters = null
  },
  [CLEAR_SELECTED] (state) {
    state.selected = null
  }
}

export const createMutations = <E extends Entity, ES extends EntityState<E>> () => {
  return {
    ...mutations
  } as MutationTree<ES>
}

export default mutations
