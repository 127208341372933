import firebase from 'firebase/app'
import Customer from '@/modules/customer/domain/customer'
import Searchable, { buildSearchString } from '@/modules/common/values/searchable'
import DocumentReference = firebase.firestore.DocumentReference

export default class CustomerInfo implements Searchable {
  firstname?: string
  surname?: string
  email?: string
  searchString: string
  ref: DocumentReference | null

  constructor (customer: Customer | any) {
    this.firstname = customer.firstname
    this.surname = customer.surname
    this.email = customer.email
    this.ref = customer.ref

    this.searchString = buildSearchString(this, ['firstname', 'surname', 'email'])
  }

  get fullName (): string {
    return ((this.surname || '') + ' ' + (this.firstname || '')).trim()
  }
}
