import entityActions from '@/modules/common/store/entityActions'
import Pass from '@/modules/pass/domain/pass'
import passService from '@/modules/pass/services/passService'

const actions = entityActions(passService, 'pass')

actions.publish = async function ({ dispatch }, pass: Pass) {
  const publishedPass = await passService.publish(pass)
  await dispatch('pass/refreshSelected', null, { root: true })
  return publishedPass
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
actions.publishAll = async function ({ dispatch }, companyId: string) {
  await passService.publishAll(companyId)
}

export default actions
