import { AUTH_LOG_OUT, AUTH_USER_UPDATE } from './authMutationTypes'
import AuthState from '@/modules/auth/store/authState'

const mutations = {
  async [AUTH_USER_UPDATE] (state: AuthState, action: any) {
    state.user = action.user
  },
  async [AUTH_LOG_OUT] (state: AuthState) {
    state.user = null
    state.logoutInProgress = true
  }
}

export default mutations
