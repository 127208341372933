import firebase from 'firebase/app'
import 'firebase/firestore'
import { COMPANIES, CUSTOMERS, CUSTOMERS_PROGRAM_APPLIES, PROGRAMS } from '@/modules/common/firestore/collections'
import { Entity } from '@/modules/common/domain/entity'
import CustomerLog from '@/modules/customer/domain/customerLog'
import customerLogService from '@/modules/customer/services/customerLogService'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import moment from 'moment'
import ProgramApply from '@/modules/program/domain/programApply'
import Program from '@/modules/program/domain/program'
import User from '@/modules/user/domain/user'
import Customer from '@/modules/customer/domain/customer'
import Timestamp = firebase.firestore.Timestamp

class ProgramApplyService extends EntityBaseService<ProgramApply> {
  newProgramApply (companyId: string, customerId: string, programId: string) {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(companyId)
    const customerRef = firebase.firestore().collection(CUSTOMERS).doc(customerId)
    const programRef = companyRef.collection(PROGRAMS).doc(programId)
    return new ProgramApply({
      current: 0,
      companyRef,
      customerRef,
      programRef
    })
  }

  async findAll (queryParams: any): Promise<ProgramApply[]> {
    const companyRef = await firebase.firestore().collection(COMPANIES).doc(queryParams.companyId!)
    const customerRef = await firebase.firestore().collection(CUSTOMERS).doc(queryParams.customerId!)

    const programsDocRef = await firebase.firestore()
      .collection(CUSTOMERS_PROGRAM_APPLIES)
      .where('companyRef', '==', companyRef)
      .where('customerRef', '==', customerRef)
      .get()

    return programsDocRef.docs.map(value => Entity.fromDb(value, ProgramApply))
  }

  async update (item: ProgramApply, user: User): Promise<ProgramApply> {
    const programApplyRef = await firebase.firestore()
      .collection(CUSTOMERS_PROGRAM_APPLIES)
      .doc(item.id!)

    const programApplyOrigin = Entity.fromDbDocSnapshot(await programApplyRef.get(), ProgramApply)

    await programApplyRef.set({
      updated: firebase.firestore.FieldValue.serverTimestamp(),
      current: item.current,
      rewards: item.rewards.map(customerReward => ({
        used: customerReward.used,
        validTo: Timestamp.fromMillis(customerReward.validTo!.valueOf())
      }))
    }, { merge: true })

    // create log
    const programDoc = await firebase.firestore().doc(item.programRef!.path).get()
    const program = Entity.fromDbDocSnapshot(programDoc, Program)
    const customerDoc = await firebase.firestore().doc(item.customerRef!.path).get()
    const customer = Entity.fromDbDocSnapshot(customerDoc, Customer)
    await customerLogService.create(new CustomerLog({
      text: 'state.update',
      args: [item.current, item.rewardsAvailable, programApplyOrigin.current, programApplyOrigin.rewardsAvailable],
      companyRef: item.companyRef,
      program: program.info,
      customer: customer.info,
      user: user.info
    }))
    return item
  }

  async create (item: ProgramApply, user: User): Promise<ProgramApply> {
    const programDoc = await firebase.firestore().doc(item.programRef!.path).get()
    const program = Entity.fromDbDocSnapshot(programDoc, Program)
    const customerRef = firebase.firestore().doc(item.customerRef!.path!)

    const newItem = await firebase.firestore()
      .collection(CUSTOMERS_PROGRAM_APPLIES)
      .add({
        current: item.current,
        companyRef: item.companyRef,
        customerRef: item.customerRef,
        programRef: item.programRef,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        programValidTo: Timestamp.fromMillis(moment(program.validTo!).valueOf()),
        maxRewardValidTo: Timestamp.fromMillis(moment(program.validTo!).add(program.rewardExpiration, 'd')!.valueOf()),
        rewards: item.rewards.map(customerReward => ({
          used: customerReward.used,
          validTo: Timestamp.fromMillis(customerReward.validTo!.valueOf())
        }))
      })

    // create log
    const customerDoc = await customerRef.get()
    const customer = Entity.fromDbDocSnapshot(customerDoc, Customer)
    await customerLogService.create(new CustomerLog({
      text: 'state.update',
      args: [item.current, item.rewardsAvailable],
      companyRef: item.companyRef,
      program: program.info,
      customer: customer.info,
      user: user.info
    }))
    const docSnapshot = await newItem.get()
    return Entity.fromDbDocSnapshot(docSnapshot, ProgramApply)
  }
}

export default new ProgramApplyService()
