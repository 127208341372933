import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/firestore'
import { FIREBASE_CONFIG, FIREBASE_FUNCTIONS_EMULATOR, FIREBASE_REGION } from '@/config'

let functionsInst: firebase.functions.Functions
let app: firebase.app.App

export default {
  init (name?: string) {
    if (!app) {
      app = firebase.initializeApp(FIREBASE_CONFIG, name)
      functionsInst = app.functions(FIREBASE_REGION)
      if (FIREBASE_FUNCTIONS_EMULATOR) {
        functionsInst.useFunctionsEmulator(FIREBASE_FUNCTIONS_EMULATOR)
      }
    }
    return app
  },

  functions: () => functionsInst
}
