

































































import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { formModal, textAreaField, textField, numberField }
})
export default class LocationCreateModal extends mixins(createModal) {
  moduleName = 'location'
}
