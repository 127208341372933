import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import PassData from '@/modules/pass/domain/passData'
import DocumentReference = firebase.firestore.DocumentReference

export default class Pass extends Entity {
  companyRef?: DocumentReference
  draft?: PassData
  published?: PassData

  get hasChange () {
    return !_.isEqual(_.omit(this.draft, 'updated'), _.omit(this.published, ['updated', 'companyRef']))
  }

  get publishAvailable () {
    return !this.published || !this.published.updated || this.published.updated.isBefore(moment().startOf('day'))
  }

  publish () {
    if (this.draft) {
      this.published = _.cloneDeep(this.draft)
    }
  }
}
