import cs from '@/i18n/cs'
import { LOCALE } from '@/config'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'

Vue.use(VueI18n)

moment.locale('cs')

const i18n = new VueI18n({
  locale: LOCALE,
  messages: {
    cs
  }
})

export default i18n
