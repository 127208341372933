import _ from 'lodash'
import firebase from 'firebase/app'
import { Entity } from '@/modules/common/domain/entity'
import DocumentReference = firebase.firestore.DocumentReference
import GeoPoint = firebase.firestore.GeoPoint

/**
 * ID of location entity is index in company array
 */
class Location extends Entity {
  companyRef?: DocumentReference
  name?: string
  latitude?: number
  longitude?: number
  altitude?: number
  notificationText?: string

  constructor (data: any) {
    super(data)
    if (data.geoLocation) {
      this.latitude = data.geoLocation.latitude
      this.longitude = data.geoLocation.longitude
    }
  }

  dbData () {
    return _.pickBy({
      name: this.name,
      altitude: this.altitude,
      notificationText: this.notificationText,
      geoLocation: new GeoPoint(this.latitude!, this.longitude!)
    })
  }
}

export default Location
