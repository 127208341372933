import Color from '@/modules/common/domain/color'

class CompanyColors {
  label?: Color | null = null
  text?: Color | null = null
  background?: Color | null = null

  constructor (data?: any) {
    this.label = data.label ? new Color(data.label) : new Color()
    this.text = data.text ? new Color(data.text) : new Color()
    this.background = data.background ? new Color(data.background) : new Color()
  }
}

export default CompanyColors
