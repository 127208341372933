import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import customerService from '@/modules/customer/services/customerService'
import Customer from '@/modules/customer/domain/customer'

const customerModule: Module<EntityState<Customer>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(customerService, 'customer')
}

export default customerModule
