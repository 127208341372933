





















































import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import Customer from '@/modules/customer/domain/customer'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import The from '@/modules/common/components/table/the.vue'

@Component({
  components: { The, noRecords, loading }
})
export default class CustomersTable extends mixins(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'customer'

  @State('managed', { namespace: 'company' }) company!: Company
  @Getter('all', { namespace: 'customer' }) allCustomers!: Array<Customer>

  defaultFilter () {
    this.sortData.asc = true
    this.sortData.by = 'email'
  }

  detail (id: string) {
    this.$router.push({ name: 'customer', params: { customerId: id } })
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(true, { companyId: this.company.id })
  }
}
