








import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Customer from '@/modules/customer/domain/customer'
import CompanyApply from '@/modules/company/domain/companyApply'

@Component
export default class CustomerInfoBox extends Vue {
  @Prop({ type: Object, required: true }) customer?: Customer
  @Prop({ type: Object, required: true }) companyApply?: CompanyApply
}
