import _ from 'lodash'

class GoogleData {
  hexBackgroundColor?: string | null = null
  heroImage?: string | null = null
  programLogo?: string | null = null

  constructor (data?: any) {
    _.merge(this, data)
  }
}

export default GoogleData
