import _ from 'lodash'
import moment from 'moment'

class AppleData {
  logo?: string | null = null
  logo2x?: string | null = null
  logo3x?: string | null = null
  backgroundColor?: string | null = null
  certificate?: string | null = null
  certificatePassword?: string | null = null
  certificateValidTo?: moment.Moment | null = null
  passTypeIdentifier?: string | null = null
  foregroundColor?: string | null = null
  icon?: string | null = null
  icon2x?: string | null = null
  icon3x?: string | null = null
  labelColor?: string | null = null
  strip?: string | null = null
  strip2x?: string | null = null
  strip3x?: string | null = null

  constructor (data?: any) {
    _.merge(this, data)

    this.certificateValidTo = data.certificateValidTo ? moment(data.certificateValidTo.toMillis()) : null
  }
}

export default AppleData
