import { Entity } from '@/modules/common/domain/entity'

export default class EntityCreateParams<T extends Entity> {
  entity: T
  doFetchAfter: boolean

  constructor (entity: T, doFetchAfter = true) {
    this.entity = entity
    this.doFetchAfter = doFetchAfter
  }
}
