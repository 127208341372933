import _ from 'lodash'

class Color {
  red?: number | null = null
  green?: number | null = null
  blue?: number | null = null

  constructor (data?: any) {
    if (data) {
      this.red = data.red
      this.green = data.green
      this.blue = data.blue
    }
  }

  get rgbString () {
    return `rgb(${this.red || 0},${this.green || 0},${this.blue || 0})`
  }

  get hexString () {
    const redHex = _.padStart((this.red || 0).toString(16), 2, '0').toLocaleUpperCase()
    const greenHex = _.padStart((this.green || 0).toString(16), 2, '0').toLocaleUpperCase()
    const blueHex = _.padStart((this.blue || 0).toString(16), 2, '0').toLocaleUpperCase()
    return `#${redHex}${greenHex}${blueHex}`
  }
}

export default Color
