
































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import moment from 'moment'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import Program from '@/modules/program/domain/program'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { Getter } from 'vuex-class'

@Component({
  components: { dateTimeField, formModal, numberField, textAreaField, textField }
})
export default class ProgramCreateModal extends mixins(createModal) {
  moduleName = 'program'

  @Getter('all', { namespace: 'program' }) programs!: Array<Program>

  validate () {
    if (this.item.validFrom.isAfter(this.item.validTo)) {
      notificationService.error('error.program.valid.from.invalid')
      return false
    } else if (this.item.validTo.endOf('day').isBefore(moment())) {
      notificationService.error('error.program.valid.to.invalid')
      return false
    }

    const overlappedProgram = _.some(this.programs, program => this.item.id ? (this.item.id !== program.id && this.item.overlap(program)) : this.item.overlap(program))
    if (overlappedProgram) {
      notificationService.error('error.program.date.overlap')
      return false
    }

    return true
  }
}
