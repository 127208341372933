import _ from 'lodash'
import firebase from 'firebase/app'
import moment from 'moment'
import Labeled from '@/modules/common/values/labeled'
import Searchable, { buildSearchString } from '@/modules/common/values/searchable'
import Timestamp = firebase.firestore.Timestamp
import DocumentReference = firebase.firestore.DocumentReference

class Entity implements Labeled, Searchable {
  id?: string | null
  ref: DocumentReference | null
  created?: moment.Moment | null
  value: any
  label: string
  searchString: string
  labelDescription?: string

  constructor (data?: any, idProp = 'id', labelProp = 'label', searchProps?: Array<string>) {
    _.merge(this, data)
    if (data && data.created) {
      this.created = this.convertDate(data.created)
    }

    this.ref = data.ref ? data.ref : null

    this.value = (this as any)[idProp] || ''
    this.label = (this as any)[labelProp] || ''
    this.searchString = buildSearchString(this, searchProps || ['label'])
  }

  convertDate (date: Timestamp): moment.Moment | null {
    return date ? moment(date.toMillis()) : null
  }

  static fromDbDocSnapshot<T extends Entity> (entityObj: firebase.firestore.DocumentSnapshot, ConstructorFn: new (data: any) => T): T {
    const entityData = entityObj.data()!

    return new ConstructorFn({ id: entityObj.id, ref: entityObj.ref, ...entityData })
  }

  static fromDb<T extends Entity> (entityObj: firebase.firestore.QueryDocumentSnapshot, ConstructorFn: new (data: any) => T): T {
    const entityData = entityObj.data()!

    return new ConstructorFn({ id: entityObj.id, ref: entityObj.ref, ...entityData })
  }
}

export { Entity }
