import User from '@/modules/user/domain/user'

export default class AuthState {
  user: User | null
  logoutInProgress = false

  constructor (user?: User | null) {
    this.user = user || null
  }
}
