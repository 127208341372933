import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/firestore'
import Pass from '@/modules/pass/domain/pass'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import PassData from '@/modules/pass/domain/passData'
import {
  COMPANIES,
  COMPANIES_DATA,
  COMPANIES_DATA_DRAFT_DOC,
  COMPANIES_DATA_LOG,
  COMPANIES_DATA_LOG_PUBLISHED_PASSES,
  COMPANIES_DATA_PUBLISHED
} from '@/modules/common/firestore/collections'

class PassService extends EntityBaseService<Pass> {
  async find (companyId: string): Promise<Pass> {
    const docRef = await firebase.firestore().collection(COMPANIES).doc(companyId)
    return this.transformPass(docRef)
  }

  async publish (pass: Pass) {
    if (pass.hasChange) {
      const companyDoc = await firebase.firestore().doc(pass.companyRef!.path)
      pass.publish()
      const publishedNewData: any = {}
      publishedNewData.sections = pass.published!.sections.map(section => ({ ...section }))
      publishedNewData.contacts = pass.published!.contacts.map(contact => ({ ...contact }))
      publishedNewData.updated = firebase.firestore.FieldValue.serverTimestamp()
      publishedNewData.companyRef = companyDoc
      const publishedData = await firebase.firestore().collection(COMPANIES_DATA_PUBLISHED).doc(companyDoc.id)
      await publishedData.set(_.pickBy(publishedNewData))
      publishedNewData.published = firebase.firestore.FieldValue.serverTimestamp()
      await companyDoc.collection(COMPANIES_DATA).doc(COMPANIES_DATA_LOG).collection(COMPANIES_DATA_LOG_PUBLISHED_PASSES).doc().set(publishedNewData)
      return pass
    } else {
      throw new Error('error.pass.publish.useless')
    }
  }

  async publishAll (companyId: string) {
    const publishedData = await firebase.firestore().collection(COMPANIES_DATA_PUBLISHED).doc(companyId)
    await publishedData.update({ updated: firebase.firestore.FieldValue.serverTimestamp() })
  }

  async update (pass: Pass): Promise<Pass> {
    const companyDoc = await firebase.firestore().doc(pass.companyRef!.path)

    const draftData: any = {}
    draftData.sections = pass.draft!.sections.map(section => ({ ...section }))
    draftData.contacts = pass.draft!.contacts.map(contact => ({ ...contact }))
    draftData.updated = firebase.firestore.FieldValue.serverTimestamp()
    await companyDoc.collection(COMPANIES_DATA).doc(COMPANIES_DATA_DRAFT_DOC).set(draftData)
    return pass
  }

  private async transformPass (companyRef: firebase.firestore.DocumentReference) {
    const dataCol = companyRef.collection(COMPANIES_DATA)
    const draftObj = await dataCol.doc(COMPANIES_DATA_DRAFT_DOC).get()
    const publishedObj = await firebase.firestore().collection(COMPANIES_DATA_PUBLISHED).doc(companyRef.id).get()
    const draftData = draftObj.data()
    const publishedData = publishedObj.data()

    return new Pass({
      id: companyRef.id,
      companyRef,
      draft: new PassData(draftData),
      published: new PassData(publishedData)
    })
  }
}

export default new PassService()
