import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import Program from '@/modules/program/domain/program'
import { Entity } from '@/modules/common/domain/entity'
import { COMPANIES, PROGRAMS } from '@/modules/common/firestore/collections'
import moment from 'moment'
import Timestamp = firebase.firestore.Timestamp

class ProgramService extends EntityBaseService<Program> {
  newProgram (companyId: string) {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(companyId)
    return new Program({
      companyRef
    })
  }

  async findAll (queryParams: any): Promise<Program[]> {
    const companyRef = firebase.firestore()
      .collection(COMPANIES)
      .doc(queryParams.companyId!)

    const programsDocRef = await companyRef
      .collection(PROGRAMS)
      .orderBy('validTo', 'desc')
      .get()

    const programs = programsDocRef.docs.map(value => Entity.fromDb(value, Program))

    // set companyRef for later usage
    programs.forEach(program => {
      program.companyRef = companyRef
    })

    return programs
  }

  async create (item: Program): Promise<Program> {
    const newItem = await firebase.firestore()
      .doc(item.companyRef!.path)
      .collection(PROGRAMS)
      .add({
        name: item.name,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        validTo: Timestamp.fromMillis(moment(item.validTo!).valueOf()),
        validFrom: Timestamp.fromMillis(moment(item.validFrom!).valueOf()),
        description: item.description,
        rewardExpiration: item.rewardExpiration,
        maxStamps: item.maxStamps
      })
    const docSnapshot = await newItem.get()

    return Entity.fromDbDocSnapshot(docSnapshot, Program)
  }

  async update (item: Program): Promise<Program> {
    const newItem = await firebase.firestore()
      .doc(item.companyRef!.path)
      .collection(PROGRAMS)
      .doc(item.id!)

    await newItem.set({
      name: item.name,
      validTo: Timestamp.fromMillis(moment(item.validTo!).valueOf()),
      validFrom: Timestamp.fromMillis(moment(item.validFrom!).valueOf()),
      description: item.description,
      rewardExpiration: item.rewardExpiration,
      maxStamps: item.maxStamps,
      updated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })

    const docSnapshot = await newItem.get()

    return Entity.fromDbDocSnapshot(docSnapshot, Program)
  }
}

export default new ProgramService()
