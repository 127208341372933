import Credentials from '@/modules/auth/domain/credentials'
import { Route } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import User from '@/modules/user/domain/user'
import { PermissionType } from '@/modules/user/type/permissionType'
import userService from '@/modules/user/services/userService'

const REDIRECT_TO_PARAM = 'redirectTo'

class AuthService {
  async login (credentials: Credentials) {
    try {
      const auth = firebase.auth()
      await auth.signInWithEmailAndPassword(credentials.username!, credentials.password!)
      const user = await userService.me()
      // check COMPANY_ADMIN or APP_ADMIN
      if (!user!.hasAnyPermission([PermissionType.COMPANY_ADMIN, PermissionType.APP_ADMIN])) {
        await auth.signOut()
        return false
      }
      userService.updateLastLogin()
      return true
    } catch (e) {
      return false
    }
  }

  loginPath (route: Route) {
    let path = '/login'
    if (route.query[REDIRECT_TO_PARAM]) {
      path += `?${REDIRECT_TO_PARAM}=${route.query[REDIRECT_TO_PARAM]}`
    } else if (route.name !== 'login') {
      path += `?${REDIRECT_TO_PARAM}=${encodeURI(route.fullPath)}`
    }
    return path
  }

  async logOut () {
    await firebase.auth().signOut()
  }

  mainPath (currentRoute: Route, user: User) {
    const query = {}
    const redirectToVal = currentRoute.query[REDIRECT_TO_PARAM]
    let path
    if (redirectToVal) {
      path = redirectToVal as string
    } else if (user.permissions.indexOf(PermissionType.COMPANY_ADMIN) >= 0) {
      path = '/news'
    } else if (user.permissions.indexOf(PermissionType.APP_ADMIN) >= 0) {
      path = '/admin/companies'
    } else {
      throw new Error('missing.permission')
    }
    return { path, query }
  }
}

export default new AuthService()
