import { COMPANIES, CUSTOMERS, CUSTOMERS_LOGS } from '@/modules/common/firestore/collections'
import { Entity } from '@/modules/common/domain/entity'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import CustomerLog from '@/modules/customer/domain/customerLog'
import Timestamp = firebase.firestore.Timestamp

class CustomerLogService extends EntityBaseService<CustomerLog> {
  async findAll (queryParams: any): Promise<CustomerLog[]> {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(queryParams.companyId!)
    let customersLogsQuery = firebase.firestore()
      .collectionGroup(CUSTOMERS_LOGS)
      .where('companyRef', '==', companyRef)
    if (queryParams.customerId) {
      const customerRef = firebase.firestore().collection(CUSTOMERS).doc(queryParams.customerId)
      customersLogsQuery = customersLogsQuery.where('customer.ref', '==', customerRef)
    }
    if (queryParams.range) {
      if (queryParams.range.from) {
        customersLogsQuery = customersLogsQuery.where('created', '>=', Timestamp.fromMillis(queryParams.range.from.valueOf()))
      }
      if (queryParams.range.to) {
        customersLogsQuery = customersLogsQuery.where('created', '<=', Timestamp.fromMillis(queryParams.range.to.valueOf()))
      }
    }
    customersLogsQuery = customersLogsQuery.orderBy('created', 'desc')

    const customerLogsDocRef = await customersLogsQuery.get()
    return customerLogsDocRef.docs.map(value => Entity.fromDb(value, CustomerLog))
  }

  async create (item: CustomerLog): Promise<CustomerLog> {
    const newItem = await firebase.firestore()
      .doc(item.customer!.ref!.path)
      .collection(CUSTOMERS_LOGS)
      .add({
        text: item.text,
        args: item.args,
        companyRef: item.companyRef,
        program: { ...item.program },
        customer: { ...item.customer },
        user: { ...item.user },
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    const docSnapshot = await newItem.get()
    return Entity.fromDbDocSnapshot(docSnapshot, CustomerLog)
  }
}

export default new CustomerLogService()
