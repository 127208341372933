import _ from 'lodash'
import moment from 'moment'
import NewsData from '@/modules/news/domain/newsData'
import NewsDataState from '@/modules/news/store/newsDataState'
import { createGetters } from '@/modules/common/store/entityGetters'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import { MAX_NEWS_PER_DAY } from '@/config'

const getters: GetterTree<NewsDataState, RootState> = createGetters()

getters.publishedLastMonth = (state) => {
  const startOfMonth = moment().startOf('month')
  return _.countBy(state.itemsLastMonth, (newsData: NewsData) => newsData.published && newsData.published.isSameOrAfter(startOfMonth)).true || 0
}

getters.publishedToday = (state) => {
  const startOfDay = moment().startOf('day')
  return _.countBy(state.itemsLastMonth, (newsData: NewsData) => newsData.published && newsData.published.isSameOrAfter(startOfDay)).true || 0
}

getters.availableLastMonth = (state, getters) => (maxPerMonths: number) => {
  return _.max([maxPerMonths - getters.publishedLastMonth, 0])
}

getters.availableToday = (state, getters) => {
  return _.max([MAX_NEWS_PER_DAY - getters.publishedToday, 0])
}

getters.availableLastMonthInPercentage = (state, getters) => (maxPerMonths: number) => {
  return (100 / maxPerMonths) * getters.availableLastMonth(maxPerMonths)
}

export default getters
