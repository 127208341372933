




















import Component from 'vue-class-component'
import PassData from '@/modules/pass/domain/passData'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PassDataBox extends Vue {
  @Prop({ type: PassData, required: true }) model!: PassData
  @Prop({ type: String, required: true }) title?: string
  @Prop({ type: String, required: false, default: '' }) bodyBgVariant?: string
}
