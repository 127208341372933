import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import { Entity } from '@/modules/common/domain/entity'
import CompanyApply from '@/modules/company/domain/companyApply'

const CUSTOMERS = 'customers'
const COMPANY_APPLIES = 'companyApplies'

class CompanyApplyService extends EntityBaseService<CompanyApply> {
  async findByCustomerAndCompany (customerId: string, companyId: string): Promise<CompanyApply> {
    const companyRef = firebase.firestore().collection('companies').doc(companyId)
    const companyApplyQS = await firebase.firestore().collection(CUSTOMERS)
      .doc(customerId)
      .collection(COMPANY_APPLIES)
      .where('companyRef', '==', companyRef)
      .limit(1)
      .get()
    if (companyApplyQS.empty) {
      return Promise.reject(new Error('company.not.exists'))
    } else {
      const apply = companyApplyQS.docs[0]
      return Entity.fromDbDocSnapshot(apply, CompanyApply)
    }
  }
}

export default new CompanyApplyService()
