











































import Credentials from '@/modules/auth/domain/credentials'
import Component, { mixins } from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { loading, vForm, textField },
  mixins: [validationMixin]
})
export default class Login extends mixins(validationMixin, submitProtectionMixin) {
  credentials = new Credentials()

  async onSubmit () {
    const loggedIn = await this.$store.dispatch('auth/logIn', this.credentials)
    if (loggedIn) {
      await this.$router.push('/')
    } else {
      notificationService.error('error.auth.login')
    }
  }
}
