import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { PermissionType } from '@/modules/user/type/permissionType'
import firebase from 'firebase/app'
import moment from 'moment'
import UserInfo from '@/modules/user/domain/userInfo'
import DocumentReference = firebase.firestore.DocumentReference

export default class User extends Entity {
  id?: string | null
  email?: string
  firstname?: string | null
  password?: string | null
  surname?: string | null
  companyRef?: DocumentReference
  permissions: string[] = []
  lastLogin?: moment.Moment | null
  active?: boolean

  constructor (data: any) {
    super(data, 'id', 'email', ['fullName', 'email'])

    if (data.permissions) {
      // @ts-ignore
      this.permissions = data.permissions.map((permission: string) => PermissionType[permission])
      this.lastLogin = this.convertDate(data.lastLogin)
    }
  }

  get fullName (): string {
    return this.firstname + ' ' + this.surname
  }

  hasPermission (permission: PermissionType | string) {
    return this.permissions.indexOf(permission) >= 0
  }

  hasAnyPermission (permissions: PermissionType[] | string[]) {
    return _.intersection(this.permissions, permissions).length > 0
  }

  get info (): UserInfo {
    return new UserInfo(this)
  }
}
