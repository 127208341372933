export const COMPANIES = 'companies'
export const COMPANIES_DATA = 'data'
export const COMPANIES_DATA_DRAFT_DOC = 'draft'
export const COMPANIES_DATA_LOG = 'log'
export const COMPANIES_DATA_LOG_PUBLISHED_PASSES = 'publishedPasses'
export const COMPANIES_DATA_PUBLISHED = 'companiesDataPublished'
export const CUSTOMERS = 'customers'
export const CUSTOMERS_PROGRAM_APPLIES = 'customersProgramApplies'
export const CUSTOMERS_LOGS = 'customerLogs'
export const PROGRAMS = 'programs'
export const USERS = 'users'
export const NEWS = 'news'
export const NEWS_ALL_DOC = 'all'
export const NEWS_PUBLISHED = 'companiesNewsPublished'
export const NEWS_ALL_DATA = 'data'
