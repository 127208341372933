import usersPage from '@/modules/user/components/usersPage.vue'

export default [
  {
    path: '/admin/users',
    component: usersPage,
    name: 'users',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  }
]
