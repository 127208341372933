import { ActionTree } from 'vuex'
import AppState from '@/modules/app/store/AppState'
import {
  APP_LOADING_DATA_DISABLE,
  APP_LOADING_DATA_ENABLE,
  APP_PAGE_DATA_SAVE,
  APP_SUBMIT_PROTECTION_DISABLE,
  APP_SUBMIT_PROTECTION_ENABLE
} from './appMutationTypes'
import PageData from '@/modules/app/store/pageData'
import RootState from '@/store/rootState'

const actions: ActionTree<AppState, RootState> = {
  async protect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_ENABLE)
  },
  async unprotect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_DISABLE)
  },
  async loadingDataEnable ({ commit }, id: string) {
    await commit(APP_LOADING_DATA_ENABLE, id)
  },
  async loadingDataDisable ({ commit }, id: string) {
    await commit(APP_LOADING_DATA_DISABLE, id)
  },
  async savePageData ({ commit }: { commit: Function }, { id, data }: { id: string, data: PageData }) {
    await commit(APP_PAGE_DATA_SAVE, { id, data })
  }
}

export default actions
