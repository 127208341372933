import { ContactType } from '@/modules/pass/types/contactType'

export default class Contact {
  type?: ContactType | null
  value?: string | null
  name?: string | null

  constructor (type?: ContactType, value?: string, name?: string) {
    this.type = type || null
    this.value = value || null
    this.name = name || null
  }

  get link () {
    switch (this.type) {
      case ContactType.EMAIL:
        return '📧 ' + this.name
      case ContactType.PHONE:
        return '☎️ ' + this.name
      case ContactType.WEB: {
        return '🌐 ' + this.name
      }
      default: return null
    }
  }

  get href () {
    switch (this.type) {
      case ContactType.EMAIL:
        return 'mailto:' + this.value
      case ContactType.PHONE:
        return 'tel:' + this.value
      case ContactType.WEB: {
        const contactValue = this.value!
        return contactValue.toLocaleLowerCase().startsWith('http') ? contactValue : 'http://' + contactValue
      }
      default: return null
    }
  }

  get target () {
    return this.type === 'WEB' ? '_blank' : ''
  }
}
