import Vue from 'vue'
import Vuex from 'vuex'
import * as modules from './storeModules'
import RootState from '@/store/rootState'

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  modules: modules
})

export default store
