import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Company from '@/modules/company/domain/company'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import { Entity } from '@/modules/common/domain/entity'
import {
  COMPANIES,
  COMPANIES_DATA,
  COMPANIES_DATA_DRAFT_DOC, COMPANIES_DATA_LOG, COMPANIES_DATA_LOG_PUBLISHED_PASSES, COMPANIES_DATA_PUBLISHED
} from '@/modules/common/firestore/collections'
import { COMPANY_STORAGE_URL, MAX_NEWS_PER_MONTH } from '@/config'

class CompanyService extends EntityBaseService<Company> {
  newCompany () {
    return new Company({ maxNewsPerMonth: MAX_NEWS_PER_MONTH })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async findAll (queryParams: any): Promise<Company[]> {
    const companiesRef = await firebase.firestore().collection(COMPANIES).get()

    const companies = companiesRef.docs.map(value => Entity.fromDb(value, Company))

    return _.orderBy(companies, ['name'], ['asc', 'desc'])
  }

  async find (id: string): Promise<Company> {
    const companySnapshot = await firebase.firestore()
      .collection(COMPANIES)
      .doc(id)
      .get()

    if (!companySnapshot.exists) {
      return Promise.reject(new Error('company.not.exists'))
    }
    return Entity.fromDbDocSnapshot(companySnapshot, Company)
  }

  async create (item: Company): Promise<Company> {
    item.updateColors()
    const newItem = await firebase.firestore()
      .collection(COMPANIES)
      .add({
        name: item.name || null,
        urlName: item.urlName || null,
        companyId: item.companyId || null,
        vatId: item.vatId || null,
        terms: item.terms || null,
        maxNewsPerMonth: item.maxNewsPerMonth || MAX_NEWS_PER_MONTH,
        classId: null,
        branches: [],
        locations: [],
        address: { ...item.address },
        google: { ...item.google },
        apple: { ...item.apple },
        colors: {
          text: { ...item.colors.text },
          background: { ...item.colors.background },
          label: { ...item.colors.label }
        },
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    const docSnapshot = await newItem.get()
    // img urls can be construct after id to new entity has been assigned
    await newItem.set({
      apple: {
        icon: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_icon.png?alt=media`,
        icon2x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_icon%402x.png?alt=media`,
        icon3x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_icon%403x.png?alt=media`,
        logo: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_logo.png?alt=media`,
        logo2x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_logo%402x.png?alt=media`,
        logo3x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_logo%403x.png?alt=media`,
        strip: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_strip.png?alt=media`,
        strip2x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_strip%402x.png?alt=media`,
        strip3x: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fapple_strip%403x.png?alt=media`
      },
      google: {
        heroImage: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fgoogle_hero.png?alt=media`,
        programLogo: `${COMPANY_STORAGE_URL}%2F${docSnapshot.id}%2Fgoogle_logo.png?alt=media`
      }
    }, { merge: true })

    await newItem.collection(COMPANIES_DATA).doc(COMPANIES_DATA_DRAFT_DOC).set({
      sections: [],
      contacts: [],
      companyRef: newItem
    })
    await firebase.firestore().collection(COMPANIES_DATA_PUBLISHED).doc(docSnapshot.id).set({
      sections: [],
      contacts: [],
      companyRef: newItem
    })
    await newItem.collection(COMPANIES_DATA).doc(COMPANIES_DATA_LOG).collection(COMPANIES_DATA_LOG_PUBLISHED_PASSES)

    return Entity.fromDbDocSnapshot(docSnapshot, Company)
  }

  async update (item: Company): Promise<Company> {
    item.updateColors()
    const docRef = await firebase.firestore().collection(COMPANIES).doc(item.id!)
    await docRef.set({
      name: item.name || null,
      urlName: item.urlName || null,
      companyId: item.companyId || null,
      vatId: item.vatId || null,
      terms: item.terms,
      maxNewsPerMonth: item.maxNewsPerMonth,
      address: { ...item.address },
      colors: {
        text: { ...item.colors.text },
        background: { ...item.colors.background },
        label: { ...item.colors.label }
      },
      updated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })
    return item
  }
}

export default new CompanyService()
