import AuthState from '@/modules/auth/store/authState'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import { PermissionType } from '@/modules/user/type/permissionType'

const getters: GetterTree<AuthState, RootState> = {
  loggedIn: (state: AuthState) => {
    return state.user && state.user.hasAnyPermission([PermissionType.COMPANY_ADMIN, PermissionType.APP_ADMIN])
  },
  hasPermission: (state: AuthState, getters: any) => (role: string) => {
    return getters.loggedIn && state.user && state.user.hasPermission(role)
  },
  hasAnyPermission: (state: AuthState, getters: any) => (...roles: string[]) => {
    return getters.loggedIn && state.user && state.user.permissions && state.user.hasAnyPermission(roles)
  }
}

export default getters
