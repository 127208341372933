import _ from 'lodash'
import Component from 'vue-class-component'
import { compareFunction, normalize } from '@/utils'
import { Entity } from '@/modules/common/domain/entity'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Prop } from 'vue-property-decorator'
import SortData from '@/modules/common/mixins/sortData'
import Vue from 'vue'

@Component
export default class EntityTableMixin<T extends Entity> extends Vue {
  showCreateModal = false
  selectedItem?: T | null = null
  query: string | null = null
  showInactive = false
  activeSupport = true
  itemsFetchAction = 'getAll'
  itemsGetter = 'all'
  itemsActiveGetter = 'active'
  entityModuleName?: string
  sortData: SortData | null = null
  fetchActionParams = new EntityFetchParams(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  newItemTemplateDefault: Function = (data?: any) => new Entity({})

  @Prop(String) title?: string | undefined
  @Prop({ type: Boolean, required: false, default: false }) showQueryFilter?: boolean
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Prop({ type: Function, required: false, default: (item: T) => true }) filterFnc!: Function
  @Prop({ type: Function, required: false }) newItemTemplate?: Function

  get items (): Array<T> {
    let items: Array<T>
    items = this.$store.getters[this.entityModuleName + '/' + ((this.activeSupport && !this.showInactive) ? this.itemsActiveGetter : this.itemsGetter)]
    items = this.transformItems(items)
    if (this.query) {
      const regexp = new RegExp(normalize(this.query), 'g')
      return this.sortEntities(items.filter(item => !!normalize(item.searchString).match(regexp)).filter(this.filterFnc as any))
    } else {
      return this.sortEntities(_.filter(items, this.filterFnc as any))
    }
  }

  transformItems (items: T[]): any[] {
    return items
  }

  private sortEntities (entities: T[]) {
    if (!this.sortData || !this.sortData.by) {
      return entities
    } else {
      const sorted = entities.sort(compareFunction(this.sortData.by!))
      return this.sortData.asc ? sorted : sorted.reverse()
    }
  }

  sort (sortData: SortData) {
    if (!this.sortData) {
      return
    }
    this.sortData.by = sortData.by
    this.sortData.asc = sortData.asc
  }

  createShow (data?: any) {
    this.selectedItem = this.newItemTemplate ? this.newItemTemplate(data) : this.newItemTemplateDefault(data)
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  editItem (item: T) {
    this.selectedItem = _.cloneDeep(item)
    this.showCreateModal = true
  }

  // can be overridden
  async beforeFetch () {
  }

  async fetch () {
    await this.beforeFetch()
    await this.$store.dispatch(this.entityModuleName + '/' + this.itemsFetchAction, this.fetchActionParams)
  }

  async beforeMount () {
    await this.fetch()
  }
}
