import firebase from 'firebase/app'
import Contact from '@/modules/pass/domain/contact'
import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import PassSection from '@/modules/pass/domain/passSection'
import DocumentReference = firebase.firestore.DocumentReference

export default class PassData extends Entity {
  private static readonly MAX_SECTIONS = 5
  private static readonly MAX_CONTACTS = 5

  sections: Array<PassSection> = []
  contacts: Array<Contact> = []
  companyRef?: DocumentReference
  updated?: moment.Moment | null

  constructor (data: any) {
    super(data)
    this.sections = data.sections ? data.sections.map((section: any) => new PassSection(section.title, section.content)) : null
    this.contacts = data.contacts ? data.contacts.map((contact: any) => new Contact(contact.type, contact.value, contact.name)) : null
    this.updated = this.convertDate(data.updated)
  }

  get newSectionAvailable () {
    return this.sections.length < PassData.MAX_SECTIONS
  }

  get newContactAvailable () {
    return this.contacts.length < PassData.MAX_CONTACTS
  }
}
