import EntityService from '@/modules/common/services/entityService'
import { Entity } from '@/modules/common/domain/entity'
import User from '@/modules/user/domain/user'

export default abstract class EntityBaseService<T extends Entity> implements EntityService<T> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  find (id: string): Promise<T> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  findAll (queryParams: any): Promise<T[]> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create (item: T, user: User): Promise<T> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update (item: T, user: User): Promise<T> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete (item: T, user: User): Promise<boolean> {
    throw new Error('Method not implemented.')
  }
}
