import authService from '../services/authService'
import userService from '@/modules/user/services/userService'
import { AUTH_LOG_OUT, AUTH_USER_UPDATE } from './authMutationTypes'
import Credentials from '@/modules/auth/domain/credentials'
import { ActionTree } from 'vuex'
import 'firebase/auth'
import eventBus from '@/modules/common/services/eventBus'
import RootState from '@/store/rootState'
import AuthState from '@/modules/auth/store/authState'
import { PermissionType } from '@/modules/user/type/permissionType'

export default {
  async logIn ({ dispatch }, credentials: Credentials) {
    await dispatch('app/loadingDataEnable', 'login', { root: true })
    const result = await authService.login(credentials)
    if (result) {
      await dispatch('fetchMe')
    }
    await dispatch('app/loadingDataDisable', 'login', { root: true })
    return result
  },
  async logOut ({ commit, dispatch }) {
    await authService.logOut()
    await commit(AUTH_LOG_OUT)
    await dispatch('app/loadingDataEnable', 'logout-in-progress', { root: true })
    await eventBus.$emit('logout')
  },
  async fetchMe ({ dispatch, commit }) {
    const user = await userService.me()
    if (user) {
      await commit(AUTH_USER_UPDATE, {
        user: user
      })
      if (user && user.hasPermission(PermissionType.COMPANY_ADMIN) && user.companyRef) {
        await dispatch('company/selectManaged', user.companyRef.id, { root: true })
      }
      return user
    }
  }
} as ActionTree<AuthState, RootState>
