import firebase from 'firebase/app'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import firebaseContext from '@/firebase/context'
import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { COMPANIES, USERS } from '@/modules/common/firestore/collections'
import User from '../domain/user'
import { ADMIN_CHANGE_PASSWORD, ADMIN_CREATE_USER } from '@/firebase/functions'

class UserService extends EntityBaseService<User> {
  newUser (companyId: string) {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(companyId)
    return new User({
      companyRef
    })
  }

  async me () {
    const user = firebase.auth().currentUser

    if (!user) {
      return null
    }

    const adminDocRef = await firebase.firestore()
      .collection(USERS)
      .doc(user.uid)

    const adminDocSnapshot = await adminDocRef.get()

    if (!adminDocSnapshot.exists) {
      return null
    }

    return Entity.fromDbDocSnapshot(adminDocSnapshot, User)
  }

  async findAll (queryParams: any): Promise<User[]> {
    const companyRef = await firebase.firestore().collection(COMPANIES).doc(queryParams.companyId!)

    const programsDocRef = await firebase.firestore()
      .collection(USERS)
      .where('companyRef', '==', companyRef)
      .get()
    return programsDocRef.docs.map(value => Entity.fromDb(value, User))
  }

  async create (item: User): Promise<User> {
    const result = await firebaseContext.functions().httpsCallable(ADMIN_CREATE_USER)({ email: item.email, password: item.password })
    const newItem = await firebase.firestore()
      .collection(USERS)
      .doc(result.data)
    await newItem.set({
      companyRef: item.companyRef,
      firstname: item.firstname,
      surname: item.surname,
      active: true,
      email: item.email,
      permissions: [
        ...item.permissions
      ],
      created: firebase.firestore.FieldValue.serverTimestamp()
    })
    const docSnapshot = await newItem.get()
    return Entity.fromDbDocSnapshot(docSnapshot, User)
  }

  async update (item: User): Promise<User> {
    const newItem = await firebase.firestore()
      .collection(USERS)
      .doc(item.id!)

    if (!_.isNil(item.password)) {
      await firebaseContext.functions().httpsCallable(ADMIN_CHANGE_PASSWORD)({ userId: item.id, password: item.password })
    }

    await newItem.set({
      firstname: item.firstname,
      surname: item.surname,
      active: item.active,
      permissions: [
        ...item.permissions
      ],
      updated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })

    const docSnapshot = await newItem.get()
    return Entity.fromDbDocSnapshot(docSnapshot, User)
  }

  updateLastLogin () {
    const user = firebase.auth().currentUser
    if (!user) {
      return null
    }
    firebase.firestore()
      .collection(USERS)
      .doc(user.uid)
      .set({
        lastLogin: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true })
  }
}

export default new UserService()
