









































import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import Customer from '@/modules/customer/domain/customer'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { EntityMap } from '@/modules/common/domain/entityMap'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import Program from '@/modules/program/domain/program'
import { Prop } from 'vue-property-decorator'
import User from '@/modules/user/domain/user'
import Vue from 'vue'
import Range from '@/modules/common/components/form/range'
import moment from 'moment'

@Component({
  components: { dateRangeField, loading, noRecords }
})
export default class CustomerEventsTab extends Vue {
  @Prop({ type: Object, required: true }) customer?: Customer
  @Getter('all', { namespace: 'customerLog' }) customerLogs!: Array<Customer>
  @Getter('allById', { namespace: 'program' }) programsById!: EntityMap<Program>
  @Getter('allById', { namespace: 'user' }) usersById!: EntityMap<User>
  @State('managed', { namespace: 'company' }) company!: Company

  period = new Range(moment().subtract(3, 'month').startOf('month'))

  user (id: string) {
    return this.usersById[id] || {}
  }

  program (id: string) {
    return this.programsById[id] || {}
  }

  async fetch () {
    await this.$store.dispatch('customerLog/getAll', new EntityFetchParams(true, { companyId: this.company!.id, customerId: this.customer!.id, range: this.period }))
  }

  async created () {
    await this.$store.dispatch('user/getAll', new EntityFetchParams(false, { companyId: this.company!.id }))
    await this.$store.dispatch('customerLog/clearAll')
    await this.fetch()
  }
}
