import { Module } from 'vuex'
import actions from './newsDataActions'
import mutations from './newsDataMutations'
import getters from './newsDataGetters'
import RootState from '@/store/rootState'
import NewsDataState from '@/modules/news/store/newsDataState'

const newsDataModule: Module<NewsDataState, RootState> = {
  namespaced: true,
  state: new NewsDataState(),
  mutations,
  getters,
  actions
}

export default newsDataModule
