



































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import companyInfoBox from '@/modules/company/components/companyInfoBox.vue'
import companyTermsBox from '@/modules/company/components/companyTermsBox.vue'
import Component from 'vue-class-component'
import page from '../../common/components/page.vue'
import programsTable from '@/modules/program/components/programsTable.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import usersTable from '@/modules/user/components/usersTable.vue'
import Vue from 'vue'
import locationsTable from '@/modules/location/components/locationsTable.vue'

@Component({
  components: { companyTermsBox, companyInfoBox, locationsTable, page, usersTable, programsTable }
})
export default class CompaniesPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'admin.label' }, { textKey: 'companies.label', routeName: 'companies' }, { textKey: 'company.label' }])

  @Prop({ type: String, required: false }) companyId?: string

  @State('selected', { namespace: 'company' }) company!: Company

  async created () {
    await this.$store.dispatch('company/select', this.companyId)
  }
}
