import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { COMPANIES } from '@/modules/common/firestore/collections'
import Company from '@/modules/company/domain/company'
import { compareFunction } from '@/utils'
import { Entity } from '@/modules/common/domain/entity'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import Location from '@/modules/location/domain/location'

class LocationService extends EntityBaseService<Location> {
  newLocation (companyId: string) {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(companyId)
    return new Location({
      companyRef
    })
  }

  async findAll (queryParams: any): Promise<Location[]> {
    const companyRef = firebase.firestore()
      .collection(COMPANIES)
      .doc(queryParams.companyId!)

    const companyDoc = await companyRef.get()

    const company = Entity.fromDbDocSnapshot(companyDoc, Company)

    // set companyRef for later usage
    company.locations.forEach(location => {
      location.companyRef = companyRef
    })

    return company.locations
  }

  async create (item: Location): Promise<Location> {
    const companyRef = await firebase.firestore()
      .doc(item.companyRef!.path)

    const companyDoc = await companyRef.get()
    const locations = ((await companyDoc.data())!.locations || [])

    locations.push(item.dbData())

    await this.persistLocations(locations, companyRef)

    return item
  }

  async update (item: Location): Promise<Location> {
    const companyRef = await firebase.firestore()
      .doc(item.companyRef!.path)

    const companyDoc = await companyRef.get()
    const locations = ((await companyDoc.data())!.locations || [])

    // push new location into locations array
    locations[parseInt(item.id!)] = item.dbData()

    await this.persistLocations(locations, companyRef)

    return item
  }

  async delete (item: Location) {
    const companyRef = await firebase.firestore()
      .doc(item.companyRef!.path)

    const companyDoc = await companyRef.get()
    const locations = ((await companyDoc.data())!.locations || [])

    locations.splice(parseInt(item.id!), 1)

    await this.persistLocations(locations, companyRef)

    return true
  }

  private async persistLocations (locations: Location[], companyRef: any) {
    locations.sort(compareFunction('name'))

    await companyRef.set({
      locations,
      updated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })
  }
}

export default new LocationService()
