import companyApplyService from '@/modules/company/services/companyApplyService'
import entityActions from '@/modules/common/store/entityActions'
import * as types from '@/modules/common/store/entityMutationTypes'

const actions = entityActions(companyApplyService, 'companyApply')

actions.selectByCustomerAndCompany = async function ({ dispatch, commit }, { customerId, companyId }) {
  await commit(types.SELECT, {
    item: null
  })
  await dispatch('app/loadingDataEnable', 'companyApply-select', { root: true })
  const entity = await companyApplyService.findByCustomerAndCompany(customerId, companyId)
  await commit(types.SELECT, {
    item: entity
  })
  await dispatch('app/loadingDataDisable', 'companyApply-select', { root: true })
  return entity
}

export default actions
