export const PASS_API_ROOT = process.env.VUE_APP_PASS_API_ROOT
export const PASS_DOWNLOAD_GOOGLE_API = PASS_API_ROOT + 'pass/google'
export const PASS_DOWNLOAD_APPLE_API = PASS_API_ROOT + 'pass/apple'
export const HELP_WEB_URL = process.env.VUE_APP_HELP_WEB_URL
export const REGISTRATION_WEB_URL = process.env.VUE_APP_REGISTRATION_WEB_URL
export const COMPANY_STORAGE_URL = process.env.VUE_APP_COMPANY_STORAGE_URL
export const SUPPORT_BOX_JS_URL = process.env.VUE_APP_SUPPORT_BOX_JS_URL
export const SUPPORT_BOX_CHAT_ID = process.env.VUE_APP_SUPPORT_BOX_CHAT_ID
export const SUPPORT_BOX_CHAT_SECRET = process.env.VUE_APP_SUPPORT_BOX_CHAT_SECRET
export const MAX_NEWS_PER_DAY = 1
export const MAX_NEWS_PER_MONTH = 5
export const COLOR = '#007bff'
export const LOCALE = 'cs'
export const FIREBASE_REGION = 'europe-west1'
export const FIREBASE_FUNCTIONS_EMULATOR = process.env.VUE_APP_FIREBASE_FUNCTIONS_EMULATOR
export const CERT_EXPIRATION_DAY_OFFSET = 30

export const FIREBASE_CONFIG = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENT_ID
}
