import passDetailPage from '@/modules/pass/components/passDetailPage.vue'
import passEditPage from '@/modules/pass/components/passEditPage.vue'

export default [
  {
    path: '/pass',
    name: 'pass',
    component: passDetailPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  },
  {
    path: '/pass/edit',
    name: 'passEdit',
    component: passEditPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_ADMIN'
    }
  }
]
