import _ from 'lodash'
import countries from '@/modules/common/values/countries'

class Address {
  streetName?: string | null = null
  houseNumber?: string | null = null
  city?: string | null = null
  zipCode?: string | null = null
  country?: string | null = null

  constructor (data: any = {}) {
    _.merge(this, data)
  }

  format (lined = false) {
    const result = []
    if (this.streetName && this.houseNumber) {
      result.push(this.streetName)
      result.push(this.houseNumber + ',')
    } else if (this.streetName) {
      result.push(this.streetName + ',')
    }
    if (lined && result.length > 0) {
      result[result.length - 1] = result[result.length - 1] + '\n'
    }
    if (this.zipCode) {
      result.push(this.zipCode)
    }
    if (this.city) {
      result.push(this.city + ',')
    }
    if (this.country) {
      result.push(_.find(countries, { value: this.country })!.label)
    }
    return result.join(' ')
  }
}

export default Address
