import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ConfirmMixin extends Vue {
  confirm (confirmationTextKey: string, args?: Array<any>) {
    return this.$bvModal.msgBoxConfirm(this.$t(confirmationTextKey, args) as string, {
      title: this.$t('confirmation.title') as string,
      size: 'md',
      buttonSize: 'md',
      okVariant: 'danger',
      okTitle: this.$t('confirmation.button.yes') as string,
      cancelTitle: this.$t('confirmation.button.no') as string,
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    })
  }
}
