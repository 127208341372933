



















import _ from 'lodash'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import { PermissionType } from '@/modules/user/type/permissionType'
import permissionTypes from '@/modules/user/type/permissionTypes'
import User from '../domain/user'
import Vue from 'vue'

@Component({
  components: { loading }
})
export default class PermissionsEditBox extends Vue {
  permissionTypes = permissionTypes

  @Prop({ type: User, required: true }) user!: User
  @Prop({ type: Array, required: true }) permissions!: Array<PermissionType>

  togglePermission (permission: PermissionType) {
    if (this.hasPermission(permission)) {
      this.user.permissions = _.without(this.user.permissions, permission)
    } else {
      this.user.permissions.push(permission)
    }
  }

  hasPermission (permission: PermissionType) {
    return this.user && this.user.permissions.indexOf(permission) >= 0
  }
}
