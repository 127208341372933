import _ from 'lodash'
import moment, { isMoment } from 'moment'
import StaticValue from '@/modules/common/values/staticValue'
import i18n from '@/i18n/i18n'

export const normalize = (value?: string) => {
  return (value || '').toLowerCase().normalize('NFD').replace(/[^\w]/g, '')
}

export const enumToStaticValue = (sourceEnum: any, enumName: string) => {
  const value = _(sourceEnum).keys().map(enumValue => new StaticValue(enumValue, `value.${enumName}.label.${enumValue}`)).value()
  return value
}

/**
 * Removes LSEP, PSEP and tabulator from text in parameter
 * @param text input text to be checked and fixed
 */
export const removeInvalidChars = (text?: string | null) => {
  return _.isNil(text) ? text : text.replace(/[\u2028\u2029]/g, '').replace(/[\t]/g, ' ')
}

export const FORMAT_DATE = (value?: moment.Moment) => {
  return value ? value.format('DD.MM.YYYY') : null
}

export const FORMAT_DATE_TIME = (value?: moment.Moment) => {
  return value ? moment(value).format('DD.MM.YYYY HH:mm') : null
}
export const FORMAT_TIME = (value?: moment.Moment) => {
  return value ? moment(value).format('HH:mm') : null
}
export const FORMAT_TIME_WITH_SECONDS = (value?: moment.Moment) => {
  return value ? moment(value).format('HH:mm:ss') : null
}

export const FORMAT_SYSTEM_DATE = (value?: moment.Moment | null) => {
  return value ? moment(value).format('YYYY-MM-DD') : null
}

/**
 * Compare function which support localCompare of strings.
 * @param sortBy property to be compared
 * @returns function to be used in lodash sortBy
 */
export const compareFunction = (sortBy: string) => {
  return (a: any, b: any) => {
    const propertyA = _.property(sortBy)(a)
    const propertyB = _.property(sortBy)(b)
    if (_.isNil(propertyB)) {
      return 1
    } else if (_.isNil(propertyA)) {
      return -1
    } else if (_.isString(propertyA) && _.isString(propertyB)) {
      return propertyA.localeCompare(propertyB, i18n.locale, { numeric: false })
    } else if (_.isNumber(propertyA) && _.isNumber(propertyB)) {
      const numA = propertyA as number
      const numB = propertyB as number
      return numA === numB ? 0 : (numA > numB ? 1 : -1)
    } else if (isMoment(propertyA) && isMoment(propertyB)) {
      const momentA = propertyA as moment.Moment
      const momentB = propertyB as moment.Moment
      return momentA.isSame(momentB) ? 0 : (momentA.isAfter(momentB) ? 1 : -1)
    } else {
      return 1
    }
  }
}

/**
 * Formats file size. !!!! Taken from vee-validate library !!!!
 *
 * @param {Number|String} size
 */
export const FORMAT_FILE_SIZE = (size: number): string => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  // @ts-ignore
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`
}
