
























import dateField from 'vue-ctk-date-time-picker'
import { LOCALE, COLOR } from '@/config'
import Vue from 'vue'
import moment, { Moment } from 'moment'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { dateField }
})
export default class DateTimeField extends Vue {
  locale = LOCALE
  dateFormat = 'YYYY-MM-DD'
  dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.sss'
  timeFormat = 'HH:mm:ss.sss'
  innerValue?: string | null = null
  maxDateData?: string | null = null
  readonly color = COLOR

  @Prop({ type: String }) cssClass?: string
  @Prop({ type: String, required: false }) fieldId?: string
  @Prop({ type: String }) label?: string
  @Prop({ type: String, default: 'sm' }) inputSize!: string
  @Prop({ type: Object }) maxDate?: Moment
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: String, default: '' }) validation!: string
  @Prop({ type: Object, default: () => {} }) validationObj!: any
  @Prop({ type: Object }) value!: Moment
  @Prop({ type: Boolean, default: true }) showTime!: boolean
  @Prop({ type: Boolean, default: true }) showDate!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) right!: boolean

  @Watch('innerValue')
  onInnerValueChange (value: string) {
    let valueToEmit: moment.Moment | null = null

    if (value) {
      valueToEmit = this.onlyTime ? moment(value, this.timeFormat) : moment(value)
    }

    this.$emit('input', valueToEmit)
  }

  @Watch('value')
  onValueChange (value: Moment) {
    this.seInnerValue(value)
  }

  @Watch('maxDate')
  onMaxDateChange (maxDate: Moment) {
    this.maxDateData = maxDate ? maxDate.format(this.showTime ? this.dateTimeFormat : this.dateFormat) : null
  }

  get requiredVal () {
    return ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required
  }

  get onlyDate () {
    return this.showDate && !this.showTime
  }

  get onlyTime () {
    return !this.showDate && this.showTime
  }

  private formatted () {
    if (this.showDate && this.showTime) {
      return 'lll'
    } else if (this.onlyDate) {
      return 'L'
    } else {
      return 'HH:mm'
    }
  }

  private format () {
    if (this.showDate && this.showTime) {
      return this.dateTimeFormat
    } else if (this.onlyDate) {
      return this.dateFormat
    } else {
      return this.timeFormat
    }
  }

  private seInnerValue (value?: Moment) {
    if (value) {
      if (this.onlyTime) {
        this.innerValue = value.format(this.timeFormat)
      } else if (this.onlyDate) {
        this.innerValue = value.format(this.dateFormat)
      } else {
        this.innerValue = value.format(this.dateTimeFormat)
      }
    } else {
      this.innerValue = null
    }
  }

  created () {
    this.seInnerValue(this.value)
  }
}
