















































































import $ from 'jquery'
import Component from 'vue-class-component'
import { Getter } from 'vuex-class'
import Vue from 'vue'

@Component
export default class Sidebar extends Vue {
  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean
  @Getter('hasAnyPermission', { namespace: 'auth' }) hasAnyPermission!: (permissions: string) => boolean

  mounted () {
    // need to init main side bar manually - do not work automatically after login
    const element: any = $(this.$refs.sidebar)
    element.Treeview('init')
  }
}
