import { Module } from 'vuex'
import actions from './passActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import Pass from '@/modules/pass/domain/pass'

const contactModule: Module<EntityState<Pass>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions
}

export default contactModule
