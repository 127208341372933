import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { EntityMap } from '@/modules/common/domain/entityMap'
import { GetterTree } from 'vuex'
import EntityState from '@/modules/common/store/entityState'
import RootState from '@/store/rootState'

const getters: GetterTree<EntityState<any>, RootState> = {
  active: (state) => {
    return _.filter((state.items) || [], 'active')
  },
  all: (state) => {
    return state.items || []
  },
  allById: (state, getters) => {
    const map = new EntityMap()
    getters.all.forEach((item: Entity) => {
      map[item.id!] = item
    })
    return map
  },
  first: (state) => {
    return _.first(state.items)
  }
}

export const createGetters = <E extends Entity, ES extends EntityState<E>> () => {
  return {
    ...getters
  } as GetterTree<ES, RootState>
}

export default getters
