




import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import $ from 'jquery'
import { State } from 'vuex-class'
import { SUPPORT_BOX_CHAT_ID, SUPPORT_BOX_CHAT_SECRET, SUPPORT_BOX_JS_URL } from '@/config'
import User from '@/modules/user/domain/user'
import Vue from 'vue'
import eventBus from '@/modules/common/services/eventBus'

@Component
export default class LiveChat extends Vue {
  initialized = false
  @State('user', { namespace: 'auth' }) user!: User
  @State('managed', { namespace: 'company' }) company!: Company

  created () {
    eventBus.$on('logout', () => {
      // remove iframe during logout
      if (this.initialized) {
        this.initialized = false
        $('#supportBox-iframe').remove()
      }
    })

    // @ts-ignore
    window.supportBoxChatId = SUPPORT_BOX_CHAT_ID
    // @ts-ignore
    window.supportBoxChatSecret = SUPPORT_BOX_CHAT_SECRET

    // @ts-ignore
    window.supportBoxChatVariables = {
      email: this.user.email,
      fullName: this.user.fullName,
      customerId: 12345
    }

    const plugin = document.createElement('script')
    plugin.id = 'supportBox'
    plugin.setAttribute(
      'src',
      SUPPORT_BOX_JS_URL
    )
    plugin.async = true
    $('head').append(plugin)
    this.initialized = true
  }
}
