






import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import customerLogsTable from '@/modules/customer/components/customerLogsTable.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { customerLogsTable, page }
})
export default class CustomersPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'customer-logs.label' }])
}
