import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import moment from 'moment'
import { Entity } from '@/modules/common/domain/entity'
import CustomerReward from '@/modules/reward/domain/customerReward'
import Program from '@/modules/program/domain/program'
import DocumentReference = firebase.firestore.DocumentReference

export default class ProgramApply extends Entity {
  companyRef?: DocumentReference
  customerRef?: DocumentReference
  programRef?: DocumentReference
  rewards: Array<CustomerReward> = []
  current?: number | null
  created?: moment.Moment | null
  updated?: moment.Moment | null

  constructor (data: any) {
    super(data, 'id', 'id')
    this.current = _.isNil(data.current) ? null : data.current
    this.rewards = data.rewards ? data.rewards.map((item: any) => new CustomerReward(item)) : []
    this.updated = this.convertDate(data.updated)
  }

  get rewardsAvailable () {
    return _.filter(this.rewards, { used: false }).length
  }

  async updateRewardsAvailable (value: number | null, program: Program) {
    if (value !== this.rewardsAvailable) {
      this.rewards = _.filter(this.rewards, { used: true })
      if (value) {
        _.times(value, () => {
          this.rewards.push(new CustomerReward({
            used: false,
            validTo: moment().startOf('day').add(program.rewardExpiration, 'day')
          }))
        })
      }
    }
  }

  get rewardsUsed () {
    return _.filter(this.rewards, { used: true }).length
  }
}
