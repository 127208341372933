





import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'

export default Vue.extend({
  name: 'vForm',
  components: { ValidationObserver },
  props: {
    refName: {
      type: String,
      default: 'form'
    }
  },
  methods: {
    validate (): Function {
      // @ts-ignore
      return this.$refs.form.validate()
    }
  }
})
