import { createMutations } from '@/modules/common/store/entityMutations'
import { LIST_LAST_MONTH } from '@/modules/news/store/newsMutationTypes'
import { MutationTree } from 'vuex'
import NewsDataState from '@/modules/news/store/newsDataState'

const mutations: MutationTree<NewsDataState> = createMutations()

mutations[LIST_LAST_MONTH] = (state, action) => {
  state.itemsLastMonth = action.items
  state.parametersLastMonth = action.parameters
}

export default mutations
