import _ from 'lodash'
import { FORMAT_TIME, FORMAT_TIME_WITH_SECONDS } from '@/utils'
import moment from 'moment'

export default (value?: moment.Moment, showSeconds = false) => {
  if (_.isNil(value)) {
    return null
  } else {
    return showSeconds ? FORMAT_TIME_WITH_SECONDS(value) : FORMAT_TIME(value)
  }
}
