








import Vue from 'vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'
import { Prop } from 'vue-property-decorator'

@Component
export default class NoRecords extends Vue {
  @State('loadingData', { namespace: 'app' }) loading?: Array<string>
  @Prop({ type: Array }) data?: Array<any>
  @Prop({ type: String, default: '' }) loadingId?: string
  @Prop({ type: String, default: 'common.data.notRecords' }) text?: string
}

