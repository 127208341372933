import firebase from 'firebase/app'
import Program from '@/modules/program/domain/program'
import Searchable, { buildSearchString } from '@/modules/common/values/searchable'
import DocumentReference = firebase.firestore.DocumentReference

export default class ProgramInfo implements Searchable {
  name?: string
  searchString: string
  ref: DocumentReference | null

  constructor (program: Program | any) {
    this.name = program.name
    this.ref = program.ref
    this.searchString = buildSearchString(this, ['name'])
  }
}
