import firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'
import { Entity } from '@/modules/common/domain/entity'
import ProgramInfo from '@/modules/program/domain/programInfo'
import DocumentReference = firebase.firestore.DocumentReference

export default class Program extends Entity {
  companyRef?: DocumentReference
  name?: string
  description?: string
  rewardExpiration?: number
  maxStamps?: number
  validFrom?: moment.Moment | null
  validTo?: moment.Moment | null
  created?: moment.Moment | null

  constructor (data: any) {
    super(data, 'id', 'name')

    this.validFrom = this.convertDate(data.validFrom)
    this.validTo = this.convertDate(data.validTo)
  }

  get valid () {
    const now = moment()
    return now.isSameOrAfter(this.validFrom!.startOf('day')!) && now.isSameOrBefore(this.validTo!.endOf('day')!)
  }

  overlap (other: Program) {
    return other.validFrom!.isSameOrBefore(this.validTo!.endOf('day')) && other.validTo!.isSameOrAfter(this.validFrom!.startOf('day'))
  }

  get info (): ProgramInfo {
    return new ProgramInfo(this)
  }
}
