




























































import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import locationService from '@/modules/location/services/locationService'
import Location from '@/modules/location/domain/location'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import truncate from '@/modules/common/components/truncate.vue'
import locationCreateModal from '@/modules/location/components/locationCreateModal.vue'

@Component({
  components: { loading, locationCreateModal, noRecords, truncate }
})
export default class LocationsTable extends mixins<entityTableMixin<Location>>(entityTableMixin) {
  entityModuleName = 'location'
  activeSupport = false

  @Prop({ type: Company, required: true }) company!: Company

  async beforeFetch () {
    await this.$store.dispatch('location/clearAll')
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(true, { companyId: this.company.id })
    this.newItemTemplateDefault = () => locationService.newLocation(this.company.id!)
  }
}
