





































































import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import programCreateModal from '@/modules/program/components/programCreateModal.vue'
import { Prop } from 'vue-property-decorator'
import textField from '@/modules/common/components/form/textField.vue'
import truncate from '@/modules/common/components/truncate.vue'
import programService from '@/modules/program/services/programService'

@Component({
  components: { loading, noRecords, programCreateModal, textField, truncate }
})
export default class ProgramsTable extends mixins(entityTableMixin) {
  @Prop({ type: Company, required: true }) company!: Company

  entityModuleName = 'program'
  activeSupport = false

  async created () {
    this.fetchActionParams = new EntityFetchParams(true, { companyId: this.company.id })
    this.newItemTemplateDefault = () => programService.newProgram(this.company.id!)
  }
}
