import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Customer from '@/modules/customer/domain/customer'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import { Entity } from '@/modules/common/domain/entity'

class CustomerService extends EntityBaseService<Customer> {
  async find (id: string): Promise<Customer> {
    const customerRef = await firebase.firestore()
      .collection('customers')
      .doc(id)
      .get()

    if (!customerRef.exists) {
      return Promise.reject(new Error('customer.not.exists'))
    }
    return Entity.fromDbDocSnapshot(customerRef, Customer)
  }

  async findAll (queryParams: any): Promise<Customer[]> {
    const companyRef = await firebase.firestore().collection('companies').doc(queryParams.companyId!)

    const customersDocRef = await firebase.firestore()
      .collection('customers')
      .where('companies', 'array-contains', companyRef)
      .get()

    return customersDocRef.docs.map(value => Entity.fromDb(value, Customer))
  }
}

export default new CustomerService()
