






































































import { CERT_EXPIRATION_DAY_OFFSET } from '@/config'
import Company from '@/modules/company/domain/company'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import companyService from '@/modules/company/services/companyService'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import TextField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { TextField, companyCreateModal, loading, noRecords }
})
export default class CompaniesTable extends mixins<entityTableMixin<Company>>(entityTableMixin) {
  entityModuleName = 'company'
  activeSupport = false
  certExpirationWarnDate = moment().add(CERT_EXPIRATION_DAY_OFFSET, 'd').startOf('d')
  today = moment().startOf('d')

  certExpirationWarn (company: Company) {
    return company.apple.certificateValidTo && company.apple.certificateValidTo.isBefore(this.certExpirationWarnDate) && company.apple.certificateValidTo.isAfter(this.today)
  }

  certExpirationDays (company: Company) {
    return company.apple.certificateValidTo?.fromNow()
  }

  certExpirationErr (company: Company) {
    return company.apple.certificateValidTo && company.apple.certificateValidTo.isBefore(this.today)
  }

  detail (id: string) {
    this.$router.push({ name: 'company', params: { companyId: id } })
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(true)
    this.newItemTemplateDefault = () => companyService.newCompany()
  }
}
