import FilterData from '@/modules/common/mixins/filterData'
import SortData from '@/modules/common/mixins/sortData'

export default class PageData {
  filterData: FilterData
  sortData: SortData

  constructor (filterData: FilterData, sortData: SortData) {
    this.filterData = filterData
    this.sortData = sortData
  }
}
