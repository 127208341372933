import { ActionTree } from 'vuex'
import entityActions from '@/modules/common/store/entityActions'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { LIST_LAST_MONTH } from '@/modules/news/store/newsMutationTypes'
import NewsData from '@/modules/news/domain/newsData'
import newsDataService from '@/modules/news/services/newsDataService'
import newsDataState from '@/modules/news/store/newsDataState'
import moment from 'moment'
import Range from '@/modules/common/components/form/range'
import RootState from '@/store/rootState'

const actions: ActionTree<newsDataState, RootState> = entityActions(newsDataService, 'newsData')

actions.publish = async function ({ dispatch }, newsData: NewsData) {
  const publishedPass = await newsDataService.publish(newsData)
  await dispatch('refresh')
  return publishedPass
}
actions.getLastMonth = async function ({ dispatch, commit }, entityFetchParams: EntityFetchParams) {
  await dispatch('app/loadingDataEnable', 'newsData-currentMonth', { root: true })
  const fetchParams = entityFetchParams ? entityFetchParams.parameters : {}
  fetchParams.period = new Range(moment().startOf('month'))
  const entities = await newsDataService.findAll(fetchParams)
  await commit(LIST_LAST_MONTH, {
    items: entities,
    parameters: fetchParams
  })
  await dispatch('app/loadingDataDisable', 'newsData-currentMonth', { root: true })
}
actions.refresh = async function ({ state, dispatch }) {
  await dispatch('getAll', new EntityFetchParams(true, state.parameters))
  if (state.parametersLastMonth) {
    await dispatch('getLastMonth', new EntityFetchParams(true, state.parametersLastMonth))
  }
}

export default actions
