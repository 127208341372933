import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { NewsDataType } from '@/modules/news/types/newsDataType'
import firebase from 'firebase/app'
import moment from 'moment'
import DocumentReference = firebase.firestore.DocumentReference

export default class NewsData extends Entity {
  published?: moment.Moment | null
  content?: string
  userRef?: DocumentReference
  companyRef?: DocumentReference
  publishedNumber: number

  constructor (data: any) {
    super(data)
    this.published = this.convertDate(data.published)
    this.publishedNumber = _.isNil(data.publishedNumber) ? 1 : data.publishedNumber
  }

  get state () {
    return this.published ? NewsDataType.PUBLISHED : NewsDataType.DRAFT
  }
}
