import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/firestore'
import EntityBaseService from '@/modules/common/services/entityBaseService'
import NewsData from '@/modules/news/domain/newsData'
import { Entity } from '@/modules/common/domain/entity'
import {
  COMPANIES,
  NEWS,
  NEWS_ALL_DATA,
  NEWS_ALL_DOC,
  NEWS_PUBLISHED,
  USERS
} from '@/modules/common/firestore/collections'
import Timestamp = firebase.firestore.Timestamp
import Query = firebase.firestore.Query

class NewsDataService extends EntityBaseService<NewsData> {
  newNewsData (companyId: string, userId: string) {
    const companyRef = firebase.firestore().collection(COMPANIES).doc(companyId)
    const userRef = firebase.firestore().collection(USERS).doc(userId)
    return new NewsData({
      companyRef,
      userRef
    })
  }

  async findAll (queryParams: any): Promise<NewsData[]> {
    const companyRef = await firebase.firestore().collection(COMPANIES).doc(queryParams.companyId!)

    let newDataCol: Query = companyRef
      .collection(NEWS)
      .doc(NEWS_ALL_DOC)
      .collection(NEWS_ALL_DATA)

    if (queryParams.period) {
      if (queryParams.period.from) {
        newDataCol = newDataCol.where('created', '>=', Timestamp.fromMillis(queryParams.period.from.valueOf()))
      }
      if (queryParams.period.to) {
        newDataCol = newDataCol.where('created', '<=', Timestamp.fromMillis(queryParams.period.to.valueOf()))
      }
    }
    const newsQuerySnapshot = await newDataCol.get()

    const newsData = newsQuerySnapshot.docs.map(value => Entity.fromDb(value, NewsData))

    // sorting is base on dynamic "state" prop, so need to be done outside firestore
    return _.orderBy(newsData, ['state', 'published'], ['asc', 'desc'])
  }

  async update (item: NewsData): Promise<NewsData> {
    const docRef = await firebase.firestore()
      .collection(COMPANIES)
      .doc(item.companyRef!.id!)
      .collection(NEWS)
      .doc(NEWS_ALL_DOC)
      .collection(NEWS_ALL_DATA)
      .doc(item.id!)
    await docRef.set({
      content: item.content,
      updated: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true })
    return item
  }

  async create (item: NewsData): Promise<NewsData> {
    const newItem = await firebase.firestore()
      .collection(COMPANIES)
      .doc(item.companyRef!.id!)
      .collection(NEWS)
      .doc(NEWS_ALL_DOC)
      .collection(NEWS_ALL_DATA)
      .add({
        content: item.content,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: item.userRef,
        companyRef: item.companyRef
      })
    const docSnapshot = await newItem.get()
    const newsData = Entity.fromDbDocSnapshot(docSnapshot, NewsData)
    newsData.companyRef = item.companyRef
    return newsData
  }

  async publish (newsData: NewsData) {
    if (!newsData.published) {
      const docRef = await firebase.firestore()
        .collection(COMPANIES)
        .doc(newsData.companyRef!.id!)
        .collection(NEWS)
        .doc(NEWS_ALL_DOC)
        .collection(NEWS_ALL_DATA)
        .doc(newsData.id!)

      // get current published doc for getting publishedNumber
      const currentPublishedSnapshot = await firebase.firestore().collection(NEWS_PUBLISHED)
        .doc(newsData.companyRef!.id)
        .get()

      let publishedNumber = 1
      if (currentPublishedSnapshot.exists) {
        const currentPublished = Entity.fromDbDocSnapshot(currentPublishedSnapshot, NewsData)
        publishedNumber = currentPublished.publishedNumber + 1
      }

      // update published timestamp
      await docRef.set({
        published: firebase.firestore.FieldValue.serverTimestamp(),
        publishedNumber
      }, { merge: true })
      const docSnapshot = await docRef.get()

      // store to published document
      const data = _.omit(docSnapshot.data()!, 'published')
      await firebase.firestore().collection(NEWS_PUBLISHED)
        .doc(newsData.companyRef!.id)
        .set(data)

      const newsDataPublished = Entity.fromDbDocSnapshot(docSnapshot, NewsData)
      newsDataPublished.companyRef = newsData.companyRef
      return newsDataPublished
    } else {
      throw new Error('error.newsData.publish.alreadyPublished')
    }
  }

  async delete (item: NewsData): Promise<boolean> {
    try {
      await firebase.firestore()
        .collection(COMPANIES)
        .doc(item.companyRef!.id!)
        .collection(NEWS)
        .doc(NEWS_ALL_DOC)
        .collection(NEWS_ALL_DATA)
        .doc(item.id!)
        .delete()
      return true
    } catch {
      return false
    }
  }
}

export default new NewsDataService()
