import { Entity } from '@/modules/common/domain/entity'
import firebase from 'firebase/app'
import moment from 'moment'
import CustomerInfo from '@/modules/customer/domain/customerInfo'
import ProgramInfo from '@/modules/program/domain/programInfo'
import UserInfo from '@/modules/user/domain/userInfo'
import { buildSearchString } from '@/modules/common/values/searchable'
import DocumentReference = firebase.firestore.DocumentReference

export default class CustomerLog extends Entity {
  text?: string
  args?: Array<any>
  created?: moment.Moment | null
  companyRef?: DocumentReference
  program: ProgramInfo | null
  customer: CustomerInfo | null
  user: UserInfo | null

  constructor (data: any) {
    super(data, 'id', 'text')
    this.user = data.user ? new UserInfo(data.user) : null
    this.program = data.program ? new ProgramInfo(data.program) : null
    this.customer = data.customer ? new CustomerInfo(data.customer) : null

    this.searchString = buildSearchString(this, ['user', 'program', 'customer'])
  }
}
