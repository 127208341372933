










































































import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import textField from '@/modules/common/components/form/textField.vue'
import The from '@/modules/common/components/table/the.vue'
import userCreateModal from '@/modules/user/components/userCreateModal.vue'
import userService from '@/modules/user/services/userService'
import User from '@/modules/user/domain/user'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { The, userCreateModal, companyCreateModal, loading, noRecords, textField }
})
export default class UsersTable extends mixins(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'user'
  @Prop({ type: Boolean, default: false }) showTitle!: boolean
  @Prop({ type: String, required: true }) companyId!: string

  @Getter('all', { namespace: 'user' }) users!: Array<User>

  defaultFilter () {
    this.sortData.asc = true
    this.sortData.by = 'fullName'
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(false, { companyId: this.companyId })
    this.newItemTemplateDefault = () => userService.newUser(this.companyId)
  }
}
