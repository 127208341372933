























import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'

@Component({
  components: { textAreaField, formModal }
})
export default class TermsCreateModal extends mixins(createModal) {
  moduleName = 'company'
  doFetchAfterDefault = false

  afterSuccessSave () {
    this.$emit('saved')
  }
}
