import firebase from 'firebase/app'
import { Entity } from '@/modules/common/domain/entity'
import CustomerInfo from '@/modules/customer/domain/customerInfo'
import moment from 'moment'
import DocumentReference = firebase.firestore.DocumentReference

export default class Customer extends Entity {
  firstname?: string
  surname?: string
  email?: string
  created?: moment.Moment | null
  lastActivity?: moment.Moment | null
  active?: boolean
  programApplies: Array<DocumentReference> = []

  constructor (data: any) {
    super(data, 'id', 'fullName', ['fullName', 'email'])

    this.lastActivity = this.convertDate(data.lastActivity)
  }

  get fullName (): string {
    return ((this.surname || '') + ' ' + (this.firstname || '')).trim()
  }

  get info (): CustomerInfo {
    return new CustomerInfo(this)
  }
}
