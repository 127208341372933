import $ from 'jquery'
import { Route } from 'vue-router'

export default () => {
  return (to: Route, from: Route, next: Function) => {
    if (from.meta.bodyClass) {
      $('body').removeClass(from.meta.bodyClass)
    }

    if (to.meta.bodyClass) {
      $('body').addClass(to.meta.bodyClass)
    }
    next()
  }
}
