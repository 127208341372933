







































import _ from 'lodash'
import Component from 'vue-class-component'
import Customer from '@/modules/customer/domain/customer'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import Program from '@/modules/program/domain/program'
import ProgramApply from '@/modules/program/domain/programApply'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import Vue from 'vue'
import programApplyService from '@/modules/program/services/programApplyService'
import Company from '@/modules/company/domain/company'
import ProgramApplyCreateModal from '@/modules/program/components/programApplyCreateModal.vue'

@Component({
  components: { ProgramApplyCreateModal, selectField }
})
export default class CustomerAccountBox extends Vue {
  selectedProgram: Program | null = null
  programApplyToEdit: ProgramApply | null = null
  showCreateModal = false

  @Prop({ type: Object, required: true }) customer?: Customer

  @State('managed', { namespace: 'company' }) company!: Company
  @Getter('all', { namespace: 'program' }) programs!: Array<Program>
  @Getter('all', { namespace: 'programApply' }) programApplies!: Array<ProgramApply>

  @Watch('programs')
  onProgramsChanged (programs?: Array<any>) {
    this.selectProgram(programs)
  }

  private selectProgram (programs: Array<any> | undefined) {
    if (programs && programs.length && !this.selectedProgram) {
      this.selectedProgram = _.find(programs, 'valid')
    }
  }

  programApply (programId: string) {
    let programApply = _.find(this.programApplies, programApply => programApply.programRef!.id === programId)
    if (!programApply) {
      programApply = programApplyService.newProgramApply(this.company.id!, this.customer!.id!, programId)
    }
    return programApply
  }

  updateShow () {
    this.programApplyToEdit = _.cloneDeep(this.programApply(this.selectedProgram!.id!))
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  async refreshProgramApplies () {
    await this.$store.dispatch('programApply/refresh')
    await this.$store.dispatch('customerLog/refresh')
  }

  async created () {
    await this.$store.dispatch('programApply/getAll', new EntityFetchParams(true, { companyId: this.company.id, customerId: this.customer!.id }))
    this.selectProgram(this.programs)
  }
}
