import appRoutes from '@/modules/app/routes/appRoutes'
import companyRoutes from '@/modules/company/routes/companyRoutes'
import customerRoutes from '@/modules/customer/routes/customerRoutes'
import newsRoutes from '@/modules/news/routes/newsRoutes'
import passRoutes from '@/modules/pass/routes/passRoutes'
import Router from 'vue-router'
import userRoutes from '@/modules/user/routes/userRoutes'
import Vue from 'vue'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    ...appRoutes,
    ...companyRoutes,
    ...customerRoutes,
    ...newsRoutes,
    ...passRoutes,
    ...userRoutes
  ]
})

export default router
