import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import Program from '@/modules/program/domain/program'
import programService from '@/modules/program/services/programService'
import RootState from '@/store/rootState'

const contactModule: Module<EntityState<Program>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(programService, 'program')
}

export default contactModule
