import firebase from 'firebase/app'
import User from '@/modules/user/domain/user'
import Searchable, { buildSearchString } from '@/modules/common/values/searchable'
import DocumentReference = firebase.firestore.DocumentReference

export default class UserInfo implements Searchable {
  firstname: string | null
  surname: string | null
  email: string | null
  searchString: string
  ref: DocumentReference | null

  constructor (user: User | any) {
    this.firstname = user.firstname || null
    this.surname = user.surname || null
    this.email = user.email || null
    this.ref = user.ref
    this.searchString = buildSearchString(this, ['firstname', 'surname', 'email'])
  }

  get fullName (): string {
    return ((this.surname || '') + ' ' + (this.firstname || '')).trim()
  }
}
