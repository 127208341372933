



























import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component, { mixins } from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import { State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import page from '@/modules/common/components/page.vue'
import passDataBox from '@/modules/pass/components/passDataBox.vue'
import Pass from '@/modules/pass/domain/pass'

@Component({
  components: { loading, page, passDataBox }
})
export default class PassContentPage extends mixins(confirmMixin, submitProtectionMixin) {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'pass.label' }])

  @State('managed', { namespace: 'company' }) company!: Company
  @State('selected', { namespace: 'pass' }) pass!: Pass

  publish () {
    if (!this.pass.publishAvailable) {
      throw new Error('pass.publish.not.available')
    }
    this.confirm('pass.publish.confirmation').then(async (value: boolean) => {
      if (value) {
        await this.$store.dispatch('pass/publish', this.pass)
        notificationService.success('pass.publish.success')
      }
    })
  }

  async created () {
    await this.$store.dispatch('pass/select', this.company.id)
  }
}
