











import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import Pass from '@/modules/pass/domain/pass'
import passForm from '@/modules/pass/components/passForm.vue'
import Vue from 'vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import { State } from 'vuex-class'
import Company from '@/modules/company/domain/company'

@Component({
  components: { loading, page, passForm }
})
export default class PassEditPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'pass.edit.label' }])
  pass: Pass | null = null

  @State('managed', { namespace: 'company' }) company!: Company

  async created () {
    this.pass = await this.$store.dispatch('pass/select', this.company.id)
  }
}
