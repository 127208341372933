import '@/modules/common/directives'
import router from './router'
import store from './store'
import filters from '@/modules/common/filters'
import hooks from '@/hooks'
import 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import 'admin-lte/plugins/jquery/jquery'
import 'admin-lte/dist/js/adminlte' // it must be here for loading adminLte
import App from '@/modules/app/components/app.vue'
import validationsInit from '@/modules/common/validation'
import notificationService from '@/modules/common/services/notificationService'
import Vue from 'vue'
import firebase from 'firebase/app'
import urlCleanHook from '@/hooks/urlCleanHook'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n/i18n'
import firebaseContext from '@/firebase/context'
import VueMeta from 'vue-meta'

// first step, clean invalid URL, e.g. facebook fbclid etc. Continue only if url was not cleared
if (!urlCleanHook()) {
  let app: Vue

  firebaseContext.init()

  validationsInit()

  filters()

  // register hooks
  hooks(store, router)

  // init bootstrap-vue
  Vue.use(BootstrapVue)

  Vue.config.productionTip = false

  Vue.use(VueMeta)

  firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {
      await store.dispatch('auth/fetchMe')
    }
    if (!app) {
      app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    }
  })

  notificationService.init()

  Vue.use(VueClipboard)
}
