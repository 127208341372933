/**
 * Interface for all object supporting fast searching.
 */
interface Searchable {
  searchString: string
}

export const buildSearchString = (data: any, args: Array<string> = []) => {
  let searchString = ' '
  args.forEach(arg => {
    const propValue = (data as any)[arg]
    if (propValue && propValue.searchString) {
      searchString += (propValue.searchString + ' ')
    } else {
      searchString += ((propValue || '') + ' ')
    }
  })
  // concat string without whitespaces
  searchString += searchString.replace(/\s/g, '')
  return searchString
}

export default Searchable
