import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import locationService from '@/modules/location/services/locationService'
import RootState from '@/store/rootState'
import Location from '@/modules/location/domain/location'

const locationModule: Module<EntityState<Location>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(locationService, 'location')
}

export default locationModule
